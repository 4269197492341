class ErrorNotificationService {
    static error(message, context, exception = null) {
        Rollbar.configure({
            payload: { error_context: context }
        }).error(message, exception);
    }

    static warning(message, context, exception = null) {
        Rollbar.configure({
            payload: { error_context: context }
        }).warning(message, exception);
    }
}

// Make the class globally available
window.ErrorNotificationService = ErrorNotificationService;
